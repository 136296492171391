import { Fragment } from 'react'
import Features from './LandingScreen/Features'
import Masthead from './LandingScreen/Masthead'
import ValueProposition from './LandingScreen/ValueProposition'

export default function LandingScreen() {
  return (
    <Fragment>
      <Masthead />
      <ValueProposition />
      <Features />
    </Fragment>
  )
}
