import { useEffect } from 'react'
import Button from '../components/Button'

function Allcaps({ children }) {
  return (
    <div className="text-gray-500 uppercase font-bold tracking-wide text-xs">
      {children}
    </div>
  )
}

function DescriptionList({ data = {} }) {
  return (
    <div className="text-sm">
      {Object.keys(data).map((key) => (
        <div className="grid grid-cols-3 gap-4" key={key}>
          <div className="text-gray-500 col-span-2">{key}</div>
          <div className="font-medium text-gray-800">{data[key]}</div>
        </div>
      ))}
    </div>
  )
}

const planFeatures = {
  Striker: ['20,000', '2GB', 'Level 1'],
  Cruiser: ['50,000', '10GB', 'Level 2'],
  Battlecruiser: ['125,000', '50GB', 'Level 2'],
  Dreadnought: ['300,000', '200GB', 'Level 3'],
}

const planModules = [
  'Accounts',
  'Ledger',
  'Social',
  'Commerce',
  'Booking',
  'Blockchain',
]

const planNames = ['Striker', 'Cruiser', 'Battlecruiser', 'Dreadnought']

const prices = [150, 300, 600, 1200]

function PlanFeatures({ name }) {
  const [records, storage, support] = planFeatures[name]

  return (
    <DescriptionList
      data={{
        Records: records,
        'File storage': storage,
        Support: support,
      }}
    />
  )
}

function PlanItem({ name, i }) {
  return (
    <div
      className="space-y-6 p-6 border border-gray-100 rounded-lg overflow-hidden shadow"
      key={i}
    >
      <div className="space-y-3">
        <div>
          <div className="uppercase text-xs font-bold tracking-wider text-purple-500">
            {`Level ${i + 1}`}
          </div>
          <div className="text-xl font-medium">{name}</div>
        </div>

        <div className="flex items-end space-x-1">
          <div className="text-2xl font-medium">${prices[i]}</div>
          <div className="text-md text-gray-500">/ month</div>
        </div>

        <Button
          color="purple"
          size="md"
          variant={name !== 'Cruiser' && 'flat'}
          fullWidth
        >
          {`Get ${name}`}
        </Button>
      </div>

      <div className="space-y-3">
        <Allcaps>Plan</Allcaps>
        <PlanFeatures name={name} />
      </div>

      <div className="space-y-3">
        <Allcaps>Modules</Allcaps>
        <div className="text-sm">
          {planModules.map((name) => (
            <div className="flex items-center space-x-4" key={name}>
              <svg
                className="w-5 h-5 text-green-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="font-medium text-gray-700">{name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default function PricingScreen() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="bg-white p-6 px-3">
      <div className="max-w-screen-xl mx-auto space-y-10 py-10">
        <div className="text-center space-y-6">
          <div>
            <div className="text-4xl">{'Pricing plans'}</div>
            <div className="text-xl text-gray-500">
              {'Fixed and transparent monthly payments'}
            </div>
          </div>

          <Button
            color="blue"
            variant="flat"
            size="md"
            href="https://app.warpgate.tech/register"
          >
            {'Start with free account'} &rarr;
          </Button>
        </div>

        <div className="grid md:grid-cols-4 gap-4">
          {planNames.map((name, key) => (
            <PlanItem {...{ name, i: key, key }} />
          ))}
        </div>

        <div className="py-3 space-y-3">
          <Allcaps>Support levels</Allcaps>
          <div className="text-sm text-gray-700">
            <div> {'Level 1 - Email'} </div>
            <div> {'Level 2 - Email, Chat'} </div>
            <div> {'Level 3 - Email, Chat, Phone'} </div>
          </div>
        </div>
      </div>
    </div>
  )
}
