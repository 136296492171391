const en = {
  'Masthead title': 'Instant backend for your custom web applications',
  'Masthead body':
    'We help developers eliminate costs in backend development, server maintenance, and database administration.',
  'feature1.body': `
    Query and mutate data easily using GraphQL.
    Use introspection to easily find existing schemas.
  `,
  'feature2.body': `
    Get straight to building your React, Vue or Node.js app.
    No servers to scale or manage.
  `,
  'feature.comingsoon': 'Coming soon!',
  'feature.description': `
    Build new apps like legos without the steep learning curve. Our suite of APIs makes
    development a breeze without compromising customizability.
  `,
  'feature.standardizedschema': `
    Use standard database schema structure used in web apps.
  `,
  'feature.accounts':
    'Register and authenticate users. General user management.',
  'feature.social':
    'Create posts, likes, timelines, and user-to-user interactions.',
  'feature.commerce':
    'Shopping cart, order management, and warehouse management.',
  'feature.wallets': 'Account balances, virtual credits, and general ledger.',
  'feature.blockchain':
    'Query blockchain data, create deposit addresses, and more',
  'feature.notifications': 'Send slack, telegram and email notifications.',
  'feature.booking': 'Create schedules, events, and online activities.',
  'calltoaction.body': `
    We are currently in closed beta.
    If you're interested to build an app using our
    serverless solution, enter your email below to get an exclusive invite.
  `,
}

export default en
