import { BrowserRouter, Route, Switch } from 'react-router-dom'

import AppFooter from './components/App/AppFooter'
import AppHeader from './components/App/AppHeader'

import LandingScreen from './screens/LandingScreen'
import PricingScreen from './screens/PricingScreen'

function App() {
  return (
    <BrowserRouter>
      <AppHeader />

      <Switch>
        <Route path="/pricing" component={PricingScreen} />
        <Route path="/" component={LandingScreen} />
      </Switch>

      <AppFooter />
    </BrowserRouter>
  )
}

export default App
