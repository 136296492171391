import cn from 'classnames'
import { Link } from 'react-router-dom'
import Button from '../Button'

function Navbar({ children }) {
  const classnames = cn(
    'px-6 h-20 flex items-center',
  )

  return (
    <div className={classnames}>
      {children}
    </div>
  )
}


export default function AppHeader() {
  return (
    <div className="bg-white">
      <Navbar>
        <Link to="/">
          <img src="/logo-icon.svg" width="48" alt="Warpgate Technologies" />
        </Link>

        <div className="ml-auto hidden md:flex items-center space-x-3">
          <Button
            color="purple"
            size="md"
            href="https://app.warpgate.tech/register"
          >Create an account</Button>

          <Button
            color="purple"
            size="md"
            variant="flat"
            href="https://app.warpgate.tech/login"
          >Sign in &rarr;</Button>
        </div>
      </Navbar>
    </div>
  )
}
