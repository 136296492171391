import Button from '../../components/Button'

const REGISTER_LINK = 'https://app.warpgate.tech/register'

const title = `
Build scalable apps using Warpgate as the serverless backend.
Our suite of APIs makes it easy for developers to utilize popular
features that are often needed in most modern web applications.
`

const featureSet = {
  Accounts:
    'Register and authenticate users, create groups and membership roles.',
  Ledger:
    'Create user account balances, wallets, and double-entry accounting ledger.',
  Social: 'Use social features such as posts, likes, timelines for your users.',
  Commerce: 'Functions for shopping cart, order and invoice creation.',
  Booking: 'Create schedules, events, and online activities',
  Blockchain: 'Create deposit addresses, query blockchain data, and more',
}

const icons = {
  Accounts: (
    <svg
      className="w-6 h-6"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
      />
    </svg>
  ),
  Ledger: (
    <svg
      className="w-6 h-6"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
      />
    </svg>
  ),
  Social: (
    <svg
      className="w-6 h-6"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
      />
    </svg>
  ),
  Commerce: (
    <svg
      className="w-6 h-6"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
      />
    </svg>
  ),
  Booking: (
    <svg
      className="w-6 h-6"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
      />
    </svg>
  ),
  Blockchain: (
    <svg
      className="w-6 h-6"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5"
      />
    </svg>
  ),
}

export default function Features() {
  return (
    <div className="px-6 py-20 bg-chip _bg-gray-900 text-gray-300 space-y-10">
      <div className="max-w-screen-md mx-auto">
        <div className="text-center space-y-4">
          <div className="text-lg uppercase tracking-wider text-gray-100 font-medium">
            {'Assemble new apps with confidence'}
          </div>
          <div className="text-xl text-gray-400 opacity-100">{title}</div>
        </div>
      </div>

      <div className="max-w-screen-lg mx-auto grid md:grid-cols-3 gap-4">
        {Object.keys(featureSet).map((title) => (
          <div className="bg-gray-800 rounded-xl shadow-lg" key={title}>
            <div className="px-6 py-5 flex items-center space-x-4">
              <div className="w-10 h-10 rounded-full bg-purple-700 text-purple-200 flex items-center justify-center">
                {icons[title]}
              </div>
              <div>
                <div className="text-lg text-white font-medium">{title}</div>
                <div className="h-0.5 bg-purple-500 w-8 mt-2"></div>
              </div>
            </div>

            <div className="px-6 pt-0 pb-6 text-gray-400">
              {featureSet[title]}
            </div>
          </div>
        ))}
      </div>

      <div className="max-w-screen-lg mx-auto text-center space-y-4">
        <Button color="purple" size="lg" href={REGISTER_LINK}>
          {'Create an account'}
          <span className="ml-1">&rarr;</span>
        </Button>
        <div className="text-gray-400">{'Free plan available!'}</div>
      </div>
    </div>
  )
}
