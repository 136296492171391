import Button from '../../components/Button'

const REGISTER_LINK = 'https://app.warpgate.tech/register'

function CoverSection({ children }) {
  return (
    <div style={{ minHeight: '70vh' }} className="flex items-center">
      <div className="w-full max-w-screen-lg mx-auto px-6">{children}</div>
    </div>
  )
}

export default function Masthead() {
  return (
    <div className="bg-white relative shadow-sm">
      <CoverSection>
        <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-4">
          <div className="col-span-2">
            <div className="space-y-2">
              <div className="text-sm uppercase tracking-wider font-bold text-purple-500">
                Serverless backend
              </div>

              <div className="text-5xl leading-tight">
                Build apps without writing backend code
              </div>

              <div className="text-lg text-gray-500">
                Warpgate is an API service that lets you build webapps without
                backend development work.
              </div>

              <div className="py-4 space-x-4">
                <Button
                  color="purple"
                  size="lg"
                  href={REGISTER_LINK}
                  endIcon={
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                >
                  {'Get started'}
                </Button>

                <Button
                  color="purple"
                  size="lg"
                  variant="flat"
                  href="https://docs.warpgate.tech"
                >
                  {'View docs'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </CoverSection>
    </div>
  )
}
