import cn from 'classnames'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

const padding = {
  xs: 'py-1 px-2',
  sm: 'py-1.5 px-3',
  md: 'py-2 px-3',
  lg: 'py-3 px-4',
  xl: 'py-3 px-4',
}

export default function Button({
  children,
  color,
  disabled,
  fullWidth,
  shadow = 'sm',
  size = 'sm',
  to,
  type = 'button',
  variant,
  startIcon,
  rounded = 'md',
  endIcon,
  href,
  ...props
}) {

  const bgClasses = useMemo(() => {
    if (!color) {
      return 'focus:bg-gray-100 bg-white-100 text-gray-700 border border-gray-300'
    }

    if (variant === 'flat') {
      return `focus:bg-${color}-200 bg-${color}-100 text-${color}-800 border border-${color}-100`
    }

    return `bg-${color}-500 text-white focus:bg-${color}-800 border-${color}-600`
  }, [color, variant])

  const isText = variant === 'text'

  const classnames = cn(
    !isText && 'border-b',
    `rounded-${rounded}`,
    'focus:outline-none focus:ring',
    'transition duration-200',
    `text-${size}`,
    'select-none',
    'inline-flex items-center',
    'leading-5',
    'appearance-none',
    !variant && `shadow-${shadow}`,
    padding[size],
    bgClasses,
    fullWidth && 'w-full',
    disabled && 'opacity-50 pointer-events-none',
  )

  const Component = useMemo(() => {
    if (to) return Link
    if (href) return 'a'
    return 'button'
  }, [href, to])

  return (
    <Component
      {...props}
      {...{ to, type, href }}
      className={classnames}
    >
      {startIcon && <span className="mr-2">{startIcon}</span>}
      <span className="mx-auto">{children}</span>
      {endIcon && <span className="ml-2">{endIcon}</span>}
    </Component>
  )
}
