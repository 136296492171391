import Card, { CardBody, CardHeader } from '../../components/Card'

const body = `
We used to be frustrated at how long it took to develop web applications. We would write the same
API endpoint, pagination, search and database migrations. Today, we use Warpgate API
across all our projects that enable us to focus more on product and UX.
`

export default function ValueProposition() {
  return (
    <div
      className="bg-grid bg-gray-50 px-6 py-20 space-y-20"
      style={{ minHeight: '50vh' }}
    >
      <div className="max-w-screen-md mx-auto">
        <div className="text-center space-y-4">
          <div className="text-4xl text-purple-600">
            <span className="mr-1">{'⚡️'}</span>
            {'The fastest way to build web apps'}
          </div>
          <div className="text-lg text-gray-500">{body}</div>
        </div>
      </div>

      <div className="max-w-screen-lg mx-auto grid md:grid-cols-3 gap-4">
        <Card>
          <CardHeader
            title="Prebuilt Functions"
            subtitle={<div className="mt-2 w-8 h-0.5 bg-purple-500"></div>}
            avatar={
              <svg
                className="w-6 h-6 text-purple-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            }
          />
          <CardBody>
            <div className="text-gray-500">
              {
                'Easily register users, authenticate, update balances, and other functions that are needed in most web apps.'
              }
            </div>
          </CardBody>
        </Card>

        <Card shadow="lg">
          <CardHeader
            title="GraphQL"
            subtitle={<div className="mt-2 w-8 h-0.5 bg-purple-500"></div>}
            avatar={
              <svg
                className="w-6 h-6 text-purple-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
                  clipRule="evenodd"
                />
              </svg>
            }
          />
          <CardBody>
            <div className="text-gray-500">
              {
                'Fetch nested data easily in a single query along with pagination and search options.'
              }
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardHeader
            title="Admin Dashboard"
            subtitle={<div className="mt-2 w-8 h-0.5 bg-purple-500"></div>}
            avatar={
              <svg
                className="w-6 h-6 text-purple-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M3 4a1 1 0 011-1h12a1 1 0 011 1v2a1 1 0 01-1 1H4a1 1 0 01-1-1V4zM3 10a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H4a1 1 0 01-1-1v-6zM14 9a1 1 0 00-1 1v6a1 1 0 001 1h2a1 1 0 001-1v-6a1 1 0 00-1-1h-2z" />
              </svg>
            }
          />
          <CardBody>
            <div className="text-gray-500">
              {
                'Conveniently view, search, and update your accumulated data through your dashboard.'
              }
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
