import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function FooterLabel(props) {
  return (
    <div className="font-medium uppercase text-xs mb-2 tracking-wider text-gray-700" {...props} />
  )
}

function FooterLink({ to, href, children }) {
  return (
    <div>
      {href && <a href={href} target="_blank" rel="noreferrer"> {children} </a>}
      {to && <Link to={to}>{children}</Link>}
    </div>
  )
}

export default function AppFooter() {
  const { t } = useTranslation()
  const date = new Date()
  const year = date.getFullYear()

  return (
    <div className="py-12 min-h-64 flex items-center text-gray-500">
      <div className="container mx-auto max-w-screen-xl text-sm md:grid md:grid-cols-5 gap-6 px-6 space-y-6 md:space-y-0">
        <div className="col-span-2 space-y-4">
          <img src="/logo-mono-black.svg" alt="Warpgate Tech" width={200} className="opacity-50" />

          <div>
            {'Warpgate Technologies is a product by '} <a href="https://warpgate.ventures" target="_blank" rel="noreferrer">Warpgate Ventures</a>
            {' – A Singaporean holding company in the business of forming new internet-based ventures with individuals and companies focused on Southeast Asia.'}
          </div>
          <div>&copy; 2019 - {year} • WARPGATE PTE. LTD.</div>
        </div>

        <div className="space-y-1">
          <FooterLabel>Customers</FooterLabel>
          <FooterLink to="/pricing">Pricing</FooterLink>
        </div>

        <div className="space-y-1">
          <FooterLabel>Developers</FooterLabel>
          <FooterLink href="https://docs.warpgate.tech">API Docs</FooterLink>
        </div>

        <div className="space-y-1">
          <FooterLabel>Legal</FooterLabel>
          <FooterLink href="https://docs.warpgate.tech/legal/terms-of-service">Terms of service</FooterLink>
          <FooterLink href="https://docs.warpgate.tech/legal/privacy-policy">Privacy policy</FooterLink>
        </div>
      </div>
    </div>
  )
}

