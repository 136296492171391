export function CardHeader({ avatar, action, title, subtitle }) {
  return (
    <div className="py-5 px-6 flex items-center">
      {avatar && <div className="mr-6">{avatar}</div>}

      <div>
        {title && <div className="font-medium text-lg text-gray-900">{title}</div>}
        {subtitle && <div className="text-sm text-gray-500">{subtitle}</div>}
      </div>

      {action && <div className="ml-auto">{action}</div>}
    </div>
  )
}

export function CardBody({ children }) {
  return (
    <div className="px-6 py-5">{children}</div>
  )
}

export function CardActions({ children }) {
  return (
    <div className="px-6 py-3 flex items-center justify-end">
      {children}
    </div>
  )
}

export default function Card({ shadow = 'md', children }) {
  return (
    <div className={`bg-white rounded-lg shadow-${shadow} overflow-hidden`}>
      {children}
    </div>
  )
}
